import React, {useEffect, useRef, useState} from 'react';

import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faComment, faEye, faPencil,faXmark} from "@fortawesome/free-solid-svg-icons";
import * as Sentry from "@sentry/nextjs";
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { borderRadius22, borderRadius50percent} from '../../global/Variables.js';
import messages from '../../../../src/messages';
import { FormattedMessage } from 'react-intl';



const StyledSharePerson = styled.div`

    width: 100%;
    display: block;
    overflow: visible;
    margin-top: 16px;
    height: 50px;
          //@media screen and (max-height: 800px) {
          //  margin-top: 16px;
          //  }
    padding-left:7px;
            
    >div{
      display:inline-block;
      float:left;
    }
    .personIcon{
        width:53px;
        height:53px;
        position:relative;
  
        border-radius:${borderRadius50percent};

   
        &.withBG{
            background:#D9D9D9;
        }
        svg{
            color:white;
            left:50%;
            top:50%;
            width:80%;
            font-size: 14pt !important;
            position:absolute;
            transform:translate(-50%, -50%);
        }
        img{
            color:white;
            left:50%;
            top:50%;
            width:80%;
            border-radius:${borderRadius50percent};
            width:53px !important;
            height:53px !important;

            position:absolute;
            transform:translate(-50%, -50%);
        }
    }
    //.personIcon{
    //  width:13%;
    //}
    .personName{
      width:55%;
      text-align:left;
        margin-left:15px;

    margin-top: 7px;
      p{
        font-size:16px;
        opacity:80%;
        line-height: 20px;
        margin:0;
      }
        .mt{
            margin-top:10px;
        }
    }
    .personActions{
      width:30%;
        margin-top:10px;

        @media(max-width:550px){
            width: 100%;

            text-align: left;
            >button{
                float:left !important;
                margin-left: 63px;
                float:left;
                margin-top: 0 !important;
            }
        }

      button{
      float:right;
        border:none;
        background:transparent;
        transition: all 0.3s ease;
          border-radius:${borderRadius22};
        padding:5px;
        padding-right:15px;
        padding-left:15px;
        font-size:16px;
        color:rgba(0,0,0,0.6);
        &:hover,
        &.active{
          color:black;
          background:rgba(0,0,0,0.15);
        }
        &.owner{
            margin-right:-15px;
            .downIcon{
            color:transparent;
            }
        }
            &.owner:hover,
      &.owner.active{
      cursor:default;
            color:rgba(0,0,0,0.6);
      background:none !important;
      }
      
        svg{
          margin-left:3px;
        }
      }

      .personPopup{
        display:none;
        width: 200px;
          height: 197px;
        background: white;
          border-radius:${borderRadius22};
        box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.15);
        z-index: 9;
        //display: block;
        margin-top:40px;
        position: absolute;

        padding:4px;
        &.active{
          display:block;
        }
        hr{
          margin: 0;
          margin-top:4px;
          margin-bottom:4px;
        }
        ul{
          list-style-type:none;
          text-align:left;
          padding:0;
          margin:0;

          li{
            transition:all 0.3s ease;
            font-size:18px;
            padding:5px;
              border-radius:${borderRadius22};
            padding-left:15px;
            line-height:26px;
            
           
            

            span{
              transition:all 0.3s ease;
              margin-left:10px;
              color:rgba(0,0,0,0.6);
            }
            svg{
                width:1em;
              transition:all 0.3s ease;
              color:rgba(0,0,0,0.6);
            }

            &:hover{
                cursor:pointer;
              background:rgba(0,0,0,0.15);
              span,
              svg{
                color:rgba(0,0,0,1);
              }
            }
            &.active{
              color:#3d55d7;
              span,
              svg{
                 color:#3d55d7;
                
              }
            }
          }
        }
      }
    }

  
`;


export default function SharePerson ({  props: any, sharePersonIcon,    triggerWarning,
                                         invite, usrteam, userUUID, sharePersonName, sharePersonEmail,
                                         changePersonInviteRole,
                                         removePersonInvite,
                                         sharePersonRole,shareVersionUUID,shareProductUUID,loadShareDataAPI }) {
    const [sharePersonPopupOpened, setSharePersonPopupOpened] = useState(false);


    const [userPermission, setUserPermissions] = useState("none");

    const ref = useRef(null);

    useEffect(() => {
        const handleOutSideClick = (event) => {
            if (!ref.current?.contains(event.target)) {
           //     alert("Outside Clicked.");
                setSharePersonPopupOpened(false);
            }
        };

        window.addEventListener("mousedown", handleOutSideClick);

        return () => {
            window.removeEventListener("mousedown", handleOutSideClick);
        };
    }, [ref]);

    useEffect(() => {
        if(userPermission !== sharePersonRole){
            setUserPermissions(sharePersonRole);
        }

    }, [sharePersonRole]);

    useEffect(() => {
      if(userPermission === "none"){
          setUserPermissions(sharePersonRole);
      }
    }, [userPermission]);



    const removeUserFromShare = () => {

        var shareUUID;

        if(shareProductUUID){
            shareUUID = shareProductUUID;
        }
        if(shareVersionUUID){
            shareUUID = shareVersionUUID;
        }

            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"share/"+shareUUID+"/members/"+userUUID, {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer "+ usrteam, // notice the Bearer before your token
                },
            })
                .then(response => response.json())
                .then(function(data){
                    if(data.message !== undefined){
                        Sentry.captureException("share/"+shareUUID+"/members/"+userUUID+": "+ data.message + " : " + usrteam);
                    }

                    if(data.message === undefined){
                        loadShareDataAPI();
                    }
                });

    };

    const changeUserRole = (newRole) => {

        var shareUUID;

        if(shareProductUUID){
            shareUUID = shareProductUUID;
        }
        if(shareVersionUUID){
            shareUUID = shareVersionUUID;
        }

setSharePersonPopupOpened(false);

    if(!invite){
    fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"share/"+shareUUID+"/members/"+userUUID, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            'Authorization': "Bearer "+ usrteam, // notice the Bearer before your token
        },
        body: JSON.stringify(    {
            "permissions" : newRole
        }),
    })
        .then(response => response.json())
        .then(function(data){
            if(data.message !== undefined){
                Sentry.captureException("share/"+shareUUID+"/members/"+userUUID+": "+ data.message + " : " + usrteam);

                if(data.error_code){
                    triggerWarning(data.error_code);
                }else{
                    triggerWarning(data.message);
                }
            }
            setSharePersonPopupOpened(false);
                    loadShareDataAPI();
            if(data.message === undefined){
                setUserPermissions(newRole);
            }

        });
    } else {
        setSharePersonPopupOpened(false);
        setUserPermissions(newRole);
    }
    };

    return (
        <>
            <StyledSharePerson key={sharePersonName+"key"}>


                    <div className={sharePersonIcon ? "personIcon" : "personIcon withBG"}>
                        {sharePersonIcon ?
                            <img data-closemodalautocomplete={true} style={{ maxWidth: '100%' }}
                                 src={sharePersonIcon} />
                            : <FontAwesomeIcon icon={faUser} />}

                    </div>
                <div className={'personName'}>
                        <p data-closemodalautocomplete={true}><b>{sharePersonName}</b></p>
                        <p data-closemodalautocomplete={true} className={!sharePersonName || (sharePersonName && sharePersonName.length == 0)  ? "mt" : ""}>{sharePersonEmail}</p>
                    </div>
                    <div data-closemodalautocomplete={true}  className={"personActions"}>

                        {userPermission !== "owner"?
                            <button data-closemodalautocomplete={true} onClick={() => setSharePersonPopupOpened(sharePersonPopupOpened ? false : true)}>
                                {userPermission === "none" ? <FormattedMessage {...messages.products_modal_share_roles_none}></FormattedMessage> : null}
                                {userPermission === "owner" ? <FormattedMessage {...messages.products_modal_share_roles_owner}></FormattedMessage> : null}
                                {userPermission === "view" ? <FormattedMessage {...messages.products_modal_share_roles_viewer}></FormattedMessage> : null}
                                {userPermission === "comment" ? <FormattedMessage {...messages.products_modal_share_roles_commenter}></FormattedMessage> : null}
                                {userPermission === "edit" ?  <FormattedMessage {...messages.products_modal_share_roles_editor}></FormattedMessage> : null}&nbsp;
                                    <FontAwesomeIcon  icon={faCaretDown} className={"downIcon"}/>
                            </button>
                        :  <button data-closemodalautocomplete={true} className={"owner"}>
                                {userPermission === "none" ? <FormattedMessage {...messages.products_modal_share_roles_none}></FormattedMessage>: null}
                                {userPermission === "owner" ? <FormattedMessage {...messages.products_modal_share_roles_owner}></FormattedMessage>: null}

                                {userPermission === "view" ? <FormattedMessage {...messages.products_modal_share_roles_viewer}></FormattedMessage>: null}

                                {userPermission === "comment" ? <FormattedMessage {...messages.products_modal_share_roles_commenter}></FormattedMessage>: null}

                                {userPermission === "edit" ? <FormattedMessage {...messages.products_modal_share_roles_editor}></FormattedMessage>  : null}&nbsp;
                                   <FontAwesomeIcon   icon={faCaretDown} className={"downIcon"}/>
                              </button>}


                        <div className={`${sharePersonPopupOpened ? 'active personPopup' : "personPopup"} `} ref={ref}
                        style={invite ? {height:"161px"} : null}>
                            <ul>

                                {!invite ?
                                    <li onClick={() => changeUserRole('none')}
                                        key={"productModalShareRolesNone"} id={"productModalShareRolesNone"}
                                        className={`${userPermission === 'none' ? 'active' : null} `}><FontAwesomeIcon
                                        icon={faXmark}
                                        className={'downIcon'} /><span><FormattedMessage {...messages.products_modal_share_roles_none}></FormattedMessage></span>
                                    </li>
                               :null }

                                <li      key={"productModalShareRolesView"} id={"productModalShareRolesView"}
                                    onClick={!invite ? () => changeUserRole('view') : () => changePersonInviteRole(userUUID, "view")}
                                    className={`${userPermission === 'view' ? 'active' : null} `}><FontAwesomeIcon
                                    icon={faEye}
                                    className={'downIcon'} /><span><FormattedMessage {...messages.products_modal_share_roles_viewer}></FormattedMessage></span>
                                </li>
                                <li
                                    key={"productModalShareRolesComment"} id={"productModalShareRolesComment"}
                                    onClick={!invite ? () => changeUserRole('comment') : () => changePersonInviteRole(userUUID, "comment")}
                                    className={`${userPermission === "comment" ? 'active' : null} `}><FontAwesomeIcon
                                    icon={faComment}
                                    className={"downIcon"} /><span><FormattedMessage {...messages.products_modal_share_roles_commenter}></FormattedMessage></span>
                                </li>
                                <li
                                    key={"productModalShareRolesEdit"} id={"productModalShareRolesEdit"}
                                    onClick={!invite ? () => changeUserRole('edit') : () => changePersonInviteRole(userUUID, "edit")}
                                    className={`${userPermission === "edit" ? 'active' : null} `}><FontAwesomeIcon
                                    icon={faPencil}
                                    className={"downIcon"} /><span><FormattedMessage {...messages.products_modal_share_roles_editor}></FormattedMessage></span>
                                </li>
                                <hr />
                                <li
                                    key={"productModalShareRolesRemove"} id={"productModalShareRolesRemove"}
                                    onClick={!invite ? () => removeUserFromShare() : () => removePersonInvite(userUUID)}>
                                    <span><FormattedMessage {...messages.products_modal_share_roles_remove_access}></FormattedMessage></span>
                                </li>
                            </ul>
                        </div>
                    </div>


            </StyledSharePerson>

        </>
    )
}